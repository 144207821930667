import { EGamificationRewardType } from '@constants';

export const StudentCourseSummaryTranslations = {
  RETURN_TO_DASHBOARD: {
    es: 'Volver al inicio',
    en: 'Return to dashboard',
    cat: "Tornar a l'inici",
    eus: 'Itzuli hasierara'
  },
  MY_PROGRESS: {
    es: 'Mi progreso',
    en: 'My progress',
    cat: 'El meu progrés',
    eus: 'Nire aurrerapena'
  },
  TABS_CONTENT_TITLE: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  TABS_INTRODUCTION_TITLE: {
    es: 'Introducción',
    en: 'Introduction',
    cat: 'Introducció',
    eus: 'Sarrera'
  },
  TABS_COMPLEMENTARY_MATERIAL_TITLE: {
    es: 'Material complementario',
    en: 'Complementary material',
    cat: 'Material complementari',
    eus: 'Material osagarria'
  },
  TABS_COURSE_INFO_TITLE: {
    es: 'Información del curso',
    en: 'Course information',
    cat: 'Informació del curs',
    eus: 'Kurtsoaren informazioa'
  },
  TABS_COURSE_MATERIAL_TITLE: {
    es: 'Material descargable',
    en: 'Downloadable material',
    cat: 'Material descarregable',
    eus: 'Material deskargagarria'
  },
  VIDEO_DESCRIPTION: {
    es: 'Video · {{duration}} min',
    en: 'Video · {{duration}} min',
    cat: 'Video · {{duration}} min',
    eus: 'Bideoa · {{duration}} min'
  },
  PDF_DESCRIPTION: {
    es: 'Lectura',
    en: 'Reading',
    cat: 'Lectura',
    eus: 'Irakurketa'
  },
  QUIZ_DESCRIPTION: {
    es: 'Test · {{questions}} preguntas',
    en: 'Test · {{questions}} questions',
    cat: 'Test · {{questions}} preguntes',
    eus: 'Test · {{questions}} galdera'
  },
  DELIVERABLE_DESCRIPTION: {
    es: 'Entregable',
    en: 'Deliverable',
    cat: 'Entregable',
    eus: 'Entregagarria'
  },
  EMPTY_CONTENT_TITLE: {
    es: 'No se ha encontrado ninguna clase',
    en: 'No class has been found',
    cat: 'No s`ha trobat cap classe',
    eus: 'Ez da klaserik aurkitu'
  },
  EMPTY_CONTENT_DESCRIPTION_ONE: {
    es: 'No te preocupes, lo más probable es que estemos actualizando el contenido.',
    en: "Don't worry, we are most likely updating the content.",
    cat: 'No et preocupis, és molt probable que estiguem actualitzant el contingut.',
    eus: 'Ez kezkatu, kontsultatu gure laguntza.'
  },
  EMPTY_CONTENT_DESCRIPTION_TWO: {
    es: 'Si el problema persistiese, puedes contactar con nuestro soporte.',
    en: 'If the problem persists, you can contact our support.',
    cat: 'Si el problema persisteix, pots contactar amb el nostre suport.',
    eus: 'Problema mantentzen bada, jarri gurekin harremanetan.'
  },
  NEW: {
    es: 'Nuevo',
    en: 'New',
    cat: 'Nou',
    eus: 'Berria'
  },
  SECTION: {
    es: 'Tema',
    en: 'Section',
    cat: 'Tema',
    eus: 'Gaia'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  SCORE: {
    es: 'Nota en test',
    en: 'Score in test',
    cat: 'Nota en test',
    eus: 'Nota testean'
  },
  NO_MATERIAL_YET: {
    en: 'There is no material yet',
    es: 'No hay material todavía',
    cat: 'Encara no hi ha material',
    eus: 'Oraindik ez dago materialik'
  },
  DOWNLOAD: {
    en: 'Download',
    es: 'Descargar',
    cat: 'Descarregar',
    eus: 'Jaitsi'
  },
  DOWNLOAD_ALL: {
    en: 'Download all',
    es: 'Descargar todo',
    cat: 'Descarregar tot',
    eus: 'Jaitsi dena'
  },
  DAYS_one: {
    es: '1 día',
    en: '1 day',
    cat: '1 dia',
    eus: '1 egun'
  },
  DAYS_other: {
    es: '{{count}} días',
    en: '{{count}} days',
    cat: '{{count}} dies',
    eus: '{{count}} egun'
  },
  PROGRESS: {
    es: 'Progreso',
    en: 'Progress',
    cat: 'Progrés',
    eus: 'Aurrerapena'
  },
  [EGamificationRewardType.ASK_DOUBT_DAILY_ACTIONS]: {
    es: 'Pregunta una duda',
    en: 'Ask a question',
    cat: 'Pregunta un dubte',
    eus: 'Galdera bat egin'
  },
  [EGamificationRewardType.COMPLETE_FREE_QUIZ_DAILY_ACTIONS]: {
    es: 'Crea y completa un test',
    en: 'Create and complete a test',
    cat: 'Crea i completa un test',
    eus: 'Sortu eta osatu test bat'
  },
  [EGamificationRewardType.COMPLETE_SECTION_DAILY_ACTIONS]: {
    es: 'Completa un tema',
    en: 'Complete a section',
    cat: 'Completa un tema',
    eus: 'Osatu gaia bat'
  },
  [EGamificationRewardType.COMPLETE_TWO_QUIZZES_DAILY_ACTIONS]: {
    es: 'Haz dos tests de lo que quieras',
    en: 'Do two tests of whatever you want',
    cat: 'Fes dos tests del que vulguis',
    eus: 'Egin bi test nahi duzunaren'
  },
  [EGamificationRewardType.COMPLETE_REVIEW_QUIZ_DAILY_ACTIONS]: {
    es: 'Completa un modo repaso',
    en: 'Complete a review mode',
    cat: 'Completa un mode repàs',
    eus: 'Osatu berrikuspen modua'
  },
  [EGamificationRewardType.SHARE_ACTIVITY_DAILY_ACTIONS]: {
    es: 'Comparte con un amigo',
    en: 'Share with a friend',
    cat: 'Comparteix amb un amic',
    eus: 'Partekatu lagun batekin'
  },
  WELCOME_TITLE: {
    es: '¡Bienvenido',
    en: 'Welcome',
    cat: '¡Benvingut',
    eus: '¡Ongi etorri'
  },
  SUPER_NAME: {
    es: 'Súper {{ name }}!',
    en: 'Super {{ name }}!',
    cat: 'Súper {{ name }}!',
    eus: 'Súper {{ name }}!'
  },
  TOOLS_TITLE: {
    es: '¿Cómo lo vas a lograr?',
    en: 'How are you going to achieve it?',
    cat: 'Com ho aconseguiràs?',
    eus: 'Nola lortuko duzu?'
  },
  USERNAME: {
    es: 'Nombre de usuario',
    en: 'Username',
    cat: 'Nom d`usuari',
    eus: 'Erabiltzaile izena'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  LAST_NAME: {
    es: 'Apellidos',
    en: 'Surname',
    cat: 'Cognoms',
    eus: 'Abizenak'
  },
  LEGAL_ID: {
    es: 'NIE/NIF',
    en: 'NIE/NIF',
    cat: 'NIE/NIF',
    eus: 'NIE/NIF'
  },
  LEGAL_ID_PLACEHOLDER: {
    es: 'DNI, NIF, NIE o pasaporte',
    en: 'DNI, NIF, NIE or passport',
    cat: 'DNI, NIF, NIE o passaport',
    eus: 'DNI, NIF, NIE edo pasaportea'
  },
  BIRTHDAY: {
    es: 'Fecha de nacimiento',
    en: 'Birthday',
    cat: 'Data de naixement',
    eus: 'Jaiotze data'
  },
  START: {
    es: 'Empezar',
    en: 'Start',
    cat: 'Començar',
    eus: 'Hasi'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu'
  },
  NOTIFICATION_SETTINGS_TITLE: {
    es: 'Te avisaremos de las próximas convocatorias y exámenes',
    en: 'We will notify you of upcoming calls and exams',
    cat: 'T’avisarem de les properes convocatòries i exàmens',
    eus: 'Jakinaraziko dizugu hurrengo deialdi eta azterketetaz'
  },
  NOTIFICATION_SETTINGS_SUBTITLE: {
    es: 'Elige sobre qué regiones quieres ser notificado. Lo podrás configurar más tarde si lo prefieres.',
    en: 'Choose about which regions you want to be notified. You can configure it later if you prefer.',
    cat: 'Tria sobre quines regions vols ser notificat. Ho podràs configurar més tard si ho prefereixes.',
    eus: 'Hautatu zein eskualdeetan jakinarazi nahi duzun. Geroago konfiguratu dezakezu nahi baduzu.'
  },
  LATER: {
    es: 'Más adelante',
    en: 'Later',
    cat: 'Més endavant',
    eus: 'Geroago'
  },
  UNKNOWN_SCORE: {
    es: 'No lo sé aún',
    en: "I don't know yet",
    cat: 'Encara no ho sé',
    eus: 'Oraindik ez dakit'
  },
  PREPARING_PLATFORM: {
    es: 'Si Batman no es nada sin Robin, tú sin plataforma tampoco. Tardará menos de un minuto (Flash es nuestro amigo). 😌',
    en: 'If Batman is nothing without Robin, you are nothing without a platform either. It will take less than a minute (Flash is our friend). 😌',
    cat: 'Si Batman no és res sense Robin, tu sense plataforma tampoc. Trigarà menys d`un minut (Flash és el nostre amic). 😌',
    eus: 'Batman ez da Robin gabe ezer, zuk plataforma gabe ere ez. Minutu baino gutxiago beharko du (Flash gure laguna da). 😌'
  },
  PLATFORM_READY: {
    es: '¡Ya tienes tu plataforma lista!',
    en: 'You already have your platform ready!',
    cat: 'Ja tens la teva plataforma llesta!',
    eus: 'Zure plataforma prest dago!'
  },
  CONTRACT_TITLE: {
    es: 'Una última cosa antes de empezar',
    en: 'One last thing before we start',
    cat: 'Una última cosa abans de començar',
    eus: 'Azkenengo gauza bat hasi aurretik'
  },
  CONTRACT_SUBTITLE: {
    es: 'Necesitamos que aceptes las condiciones antes de poder continuar. Aquí te lo dejamos para que puedas leerlo:',
    en: 'We need you to accept the conditions before we can continue. We leave it here for you to read:',
    cat: 'Necessitem que acceptis les condicions abans de poder continuar. Aquí te`l deixem perquè puguis llegir-lo:',
    eus: 'Beharrezkoa da baldintzak onartzea jarraitzeko. Hemen utzi dizugu irakurtzeko:'
  },
  START_NOW: {
    es: 'Empezar ya',
    en: 'Start now',
    cat: 'Començar ara',
    eus: 'Hasi orain'
  },
  CONTRACT_HELP: {
    es: '¿Tienes dudas? Escríbenos a atencionalalumno@ucademy.com con el asunto «Contrato» y nos pondremos en contacto contigo para que puedas continuar con el estudio 😀',
    en: 'Do you have any doubts? Write us to atencionalalumno@ucademy.com with the subject "Contract" and we will contact you so you can continue with the study 😀',
    cat: 'Tens dubtes? Escriu-nos a atencionalalumno@ucademy.com amb l`assumpte «Contracte» i ens posarem en contacte amb tu perquè puguis continuar amb l`estudi 😀',
    eus: 'Zalantzak dituzu? Idatzi iezaguzu atencionalalumno@ucademy.com helbidera "Kontratua" gaiarekin eta zurekin harremanetan jarriko gara ikasketekin jarrai dezazun 😀'
  },
  WELCOME_MESSAGE: {
    es: 'Muchas gracias y bienvenido a Ucademy! 😁',
    en: 'Thank you very much and welcome to Ucademy! 😁',
    cat: 'Moltes gràcies i benvingut a Ucademy! 😁',
    eus: 'Mila esker eta ongi etorri Ucademy! 😁'
  },
  ALL_SET: {
    es: '¡Todo listo!',
    en: 'All set',
    cat: 'Tot llest',
    eus: 'Denak prest'
  },
  YOU_CAN_START: {
    es: 'Ya puedes empezar',
    en: 'You are ready to go',
    cat: 'Ja pots començar',
    eus: 'Orain hasi dezakezu'
  },
  CONTRACT_SIGNED_SUCCESSFULLY: {
    es: '¡Contrato firmado correctamente! Encontrarás una copia en tu perfil.',
    en: 'Contract signed successfully! You will find a copy in your profile.',
    cat: 'Contracte signat correctament! Trobaràs una còpia al teu perfil.',
    eus: 'Kontratua ongi sinatu da! Kopi bat aurkituko duzu zure profila.'
  },
  STUDY_STEPS_TITLE: {
    es: 'Vamos a ir pasito a pasito',
    en: 'We are going to take it step by step',
    cat: 'Anirem pas a pas',
    eus: 'Paso a paso joango gara'
  },
  STUDY_STEPS_SUBTITLE: {
    es: '¡Cuidado! ¡Superhéroe en prácticas! Vamos a ir de la mano en todo momento para que no te pierdas y sepas lo que tienes que hacer.',
    en: 'Watch out! Superhero in training! We are going to go hand in hand at all times so you don`t get lost and know what you have to do.',
    cat: 'Atenció! Superheroi en pràctiques! Anirem de la mà en tot moment perquè no et perdis i sàpigues el que has de fer.',
    eus: 'Kontuz! Superheroi praktiketan! Gure artean joango gara beti ez galduz eta zer egin behar duzun jakin dezazun.'
  },
  STUDY_PLAN_TITLE: {
    es: 'Planificación de tu estudio',
    en: 'Planning your study',
    cat: 'Planificació del teu estudi',
    eus: 'Zure ikasketak planifikatzea'
  },
  STUDY_PLAN_SUBTITLE: {
    es: 'Para que SIEMPRE sepas qué tienes que hacer, planificaré tu estudio por tareas diarias.',
    en: 'So you ALWAYS know what you have to do, I will plan your study by daily tasks.',
    cat: 'Perquè SEMPRE sàpigues què has de fer, planificaré el teu estudi per tasques diàries.',
    eus: 'ZER egin behar duzun beti jakiteko, zure ikasketak eguneko lanak eginez planifikatuko ditut'
  },
  ONBOARDING_FOLLOW_UP_TITLE: {
    es: 'No te pierdas',
    en: 'Don`t get lost',
    cat: 'No et perdis',
    eus: 'Ez galdu'
  },
  ONBOARDING_FOLLOW_UP_SUBTITLE: {
    es: 'En la sección de inicio siempre te recomendaremos por dónde lo dejaste',
    en: 'In the home section we will always recommend where you left off',
    cat: 'A la secció d`inici sempre et recomanarem per on ho vas deixar',
    eus: 'Hasierako atalean beti gomendatuko dizugu non utzi zenuen'
  },
  ONBOARDING_REWARDS_TITLE: {
    es: 'Recompensas',
    en: 'Rewards',
    cat: 'Recompenses',
    eus: 'Sariak'
  },
  ONBOARDING_REWARDS_SUBTITLE: {
    es: '¡Te daré puntos de conocimiento cada vez que hagas las cosas bien!',
    en: 'I will give you knowledge points every time you do things right!',
    cat: 'Et donaré punts de coneixement cada vegada que facis les coses bé!',
    eus: 'Zer egiten duzun bakoitzean, ezagutza puntuak emango dizkizut!'
  },
  GENERATING_PLATFORM: {
    es: 'Generando tu plataforma {{ name }}...',
    en: 'Generating your platform {{ name }}...',
    cat: 'Generant la teva plataforma {{ name }}...',
    eus: 'Zure plataforma sortzen {{ name }}...'
  },
  THANKS_FOR_WAITING: {
    es: '¡Gracias por la espera {{ name }}!',
    en: 'Thanks for waiting {{ name }}!',
    cat: 'Gràcies per l`espera {{ name }}!',
    eus: 'Eskerrik asko itxaroten {{ name }}!'
  },
  NO_LIVE_EVENTS: {
    es: 'No te has apuntado a ninguna clase en directo',
    en: 'You have not signed up for any live classes',
    cat: 'No t`has apuntat a cap classe en directe',
    eus: 'Ez zara zuzeneko klaserik apuntatu'
  },
  SEARCH_LIVE_EVENTS: {
    es: 'Buscar clases en directo',
    en: 'Search live classes',
    cat: 'Cerca classes en directe',
    eus: 'Bilatu zuzeneko klaseak'
  },
  COMPLETED: {
    es: 'Completado',
    en: 'Completed',
    cat: 'Completat',
    eus: 'Osatuta'
  },
  GET_20_POINTS: {
    es: 'Consigue 20 puntos',
    en: 'Get 20 points',
    cat: 'Aconsegueix 20 punts',
    eus: 'Lortu 20 puntu'
  },
  LOCKED: {
    es: 'Bloqueado',
    en: 'Locked',
    cat: 'Bloquejat',
    eus: 'Blokeatuta'
  }
};
