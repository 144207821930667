import { EFeatures, ERoles } from '@constants';

import { CalendarWidget } from '../components/CalendarWidget';
import { GamificationWidget } from '../components/GamificationWidget';
import { InfoWidget } from '../components/InfoWidget';
import { LiveClassesWidget } from '../components/LiveClassesWidget';
import { OpenTicketsWidget } from '../components/OpenTicketsWidget';
import { PendingTasksWidget } from '../components/PendingTasksWidget';
import { PostWidget } from '../components/PostWidget';
import { ReportsWidget } from '../components/ReportsWidget';
import { StudentContentWidget } from '../components/StudentContentWidget';
import { TestModeWidget } from '../components/TestModeWidget';
import { TicketsWidget } from '../components/TicketsWidget';

import { ExamGroupsWidget, StudentLiveClassesWidget } from '@/components/Widgets';
import { WidgetProps } from '@/types';

type Props = Record<
  ERoles,
  {
    Widget: React.FC<WidgetProps>;
    position: { h1: number; v1: number; h2: number; v2: number };
    availableForFeature?: EFeatures;
  }[]
>;

export const WIDGETS: Props = {
  [ERoles.ADMIN]: [],
  [ERoles.MANAGER]: [
    { Widget: InfoWidget, position: { h1: 1, v1: 1, h2: 2, v2: 3 } },
    { Widget: TicketsWidget, position: { h1: 1, v1: 3, h2: 3, v2: 5 } },
    { Widget: PostWidget, position: { h1: 2, v1: 1, h2: 5, v2: 3 } },
    { Widget: CalendarWidget, position: { h1: 1, v1: 5, h2: 4, v2: 7 } },
    { Widget: ReportsWidget, position: { h1: 3, v1: 3, h2: 4, v2: 5 } }
  ],
  [ERoles.TEACHER]: [
    { Widget: PendingTasksWidget, position: { h1: 1, v1: 1, h2: 3, v2: 7 } },
    { Widget: LiveClassesWidget, position: { h1: 3, v1: 1, h2: 7, v2: 4 } },
    { Widget: OpenTicketsWidget, position: { h1: 3, v1: 4, h2: 7, v2: 7 } }
  ],
  [ERoles.STUDENT]: [
    {
      Widget: StudentContentWidget,
      position: { h1: 1, v1: 1, h2: 3, v2: 4 },
      availableForFeature: EFeatures.CONTENT
    },
    {
      Widget: TestModeWidget,
      position: { h1: 1, v1: 4, h2: 3, v2: 7 },
      availableForFeature: EFeatures.QUIZZES
    },
    {
      Widget: PostWidget,
      position: { h1: 3, v1: 1, h2: 5, v2: 3 }
    },
    {
      Widget: GamificationWidget,
      position: { h1: 3, v1: 3, h2: 4, v2: 7 }
    },
    {
      Widget: StudentLiveClassesWidget,
      position: { h1: 4, v1: 3, h2: 4, v2: 5 },
      availableForFeature: EFeatures.LIVE_CLASSES
    },
    {
      Widget: ExamGroupsWidget,
      position: { h1: 4, v1: 5, h2: 5, v2: 7 },
      availableForFeature: EFeatures.ANNOUNCEMENTS
    }
  ],
  [ERoles.SALES]: [{ Widget: InfoWidget, position: { h1: 1, v1: 1, h2: 2, v2: 3 } }],
  [ERoles.SUPPORT]: [{ Widget: InfoWidget, position: { h1: 1, v1: 1, h2: 2, v2: 3 } }],
  [ERoles.INSPECTOR]: [{ Widget: InfoWidget, position: { h1: 1, v1: 1, h2: 2, v2: 3 } }],
  [ERoles.AI_TEACHER]: []
};
