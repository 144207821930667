import { encodeQueryParams } from '@utils';
import { Dayjs } from 'dayjs';

import { RequestService } from '../request/request.service';

import { ILast14DaysActivity } from './activity-tracking.contract';
import {
  ICourseRatingStats,
  ICreateSubscription,
  ICreateSubscriptionByTag,
  IDownloadActivityRatingData,
  IRankingByCourseAggregation,
  ISectionsStudyStatus,
  ISubscription
} from './subscription.contract';

export class SubscriptionService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public create(createSubscription: ICreateSubscription) {
    return this.poster<ISubscription[], ICreateSubscription>(`/subscriptions`, createSubscription);
  }

  public toggleSubscriptionVisibility(subscriptionId: string) {
    return this.putter(`/subscriptions/toggle-subscription-visibility/${subscriptionId}`);
  }

  public remove(subscriptionId: string) {
    return this.deleter(`/subscriptions/${subscriptionId}`);
  }

  public createByTag(createSubscriptionByTag: ICreateSubscriptionByTag) {
    return this.poster<ISubscription[], ICreateSubscriptionByTag>(
      `/subscriptions/by-tag`,
      createSubscriptionByTag
    );
  }

  public getMySubscriptions() {
    return this.fetcher<ISubscription[]>(`/subscriptions/me`);
  }

  public getMySubscriptionDetail(subscriptionId: string) {
    return this.fetcher<ISubscription>(`/subscriptions/me/${subscriptionId}`);
  }

  public getUserSubscriptions(userId: string) {
    return this.fetcher<ISubscription[]>(`/subscriptions/${userId}`);
  }

  public getCourseRatingStatsByCourseId(courseId?: string, dateFilter?: [Dayjs, Dayjs]) {
    const query = new URLSearchParams();
    query.set('startDate', dateFilter?.[0]?.toISOString() || '');
    query.set('endDate', dateFilter?.[1]?.toISOString() || '');
    return this.fetcher<ICourseRatingStats>(
      `/subscriptions/stats/course-rating/${courseId}?${query.toString()}`
    );
  }

  public getSectionsStudyStatus(subscriptionId: string) {
    return this.fetcher<ISectionsStudyStatus[]>(`/subscriptions/me/${subscriptionId}/study-status`);
  }

  public downloadActyivityRatingData() {
    return this.poster<IDownloadActivityRatingData[], undefined>(
      `/subscriptions/stats/activity-rating/download`
    );
  }

  public getRankingByPeriod(courseId: string, month: number, year: number) {
    const query = encodeQueryParams({ month, year });
    return this.fetcher<IRankingByCourseAggregation[]>(
      `/subscriptions/ranking/by-course/${courseId}?${query}`
    );
  }

  public findLast14DaysActivity(subscriptionId: string) {
    return this.fetcher<ILast14DaysActivity[]>(
      `/subscriptions/${subscriptionId}/last-14-days-activity`
    );
  }
}
