import { lazy } from 'react';

import { IRoute } from '../../types/routes';

const TeacherDashboardPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.DashboardPage }))
);

const TicketsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TicketsPage }))
);

const EventsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventsPage }))
);

const EventPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventPage }))
);

const CoursePage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursePage }))
);

const CoursesPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursesPage }))
);

const QuizzesPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.QuizzesPage }))
);

const QuizFormPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.QuizFormPage }))
);

const TutoringGroupsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TutoringGroupsPage }))
);

const TutoringGroupPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TutoringGroupPage }))
);

const ChatPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.ChatPage }))
);

const ExamGroupsPage = lazy(() =>
  import('@/pages/Teacher').then(module => ({ default: module.ExamGroupsPage }))
);

const StudentsPage = lazy(() =>
  import('@/pages/Teacher').then(module => ({ default: module.StudentsPage }))
);

const ProfilePage = lazy(() =>
  import('@/pages/Teacher').then(module => ({ default: module.ProfilePage }))
);

export const TEACHER: IRoute[] = [
  {
    title: 'DASHBOARD',
    path: '/dashboard',
    icon: 'Home',
    Component: TeacherDashboardPage,
    showInMenu: true
  },
  {
    title: 'COURSES',
    path: '/courses',
    icon: 'Notebook',
    Component: CoursesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'COURSES',
        path: ':_id',
        icon: 'Undefined',
        Component: CoursePage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'DOUBTS',
    path: '/tickets',
    icon: 'MailAll',
    Component: TicketsPage,
    showInMenu: true
  },
  {
    title: 'TESTS',
    path: '/tests',
    icon: 'TestTool',
    Component: QuizzesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'TESTS',
        path: ':_id/edit',
        icon: 'Undefined',
        Component: QuizFormPage,
        showInMenu: false
      },
      {
        title: 'CREATE_TEST',
        path: 'create',
        icon: 'Undefined',
        Component: QuizFormPage,
        showInMenu: false
      },
      {
        title: 'TESTS',
        path: 'import',
        icon: 'Undefined',
        Component: QuizFormPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'STUDENTS',
    path: '/students',
    icon: 'UserData',
    Component: StudentsPage,
    showInMenu: true
  },
  {
    title: 'LIVE_EVENTS',
    path: '/calendar',
    icon: 'EventSchedule',
    Component: EventsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'LIVE_EVENTS',
        path: ':_id',
        icon: 'Undefined',
        Component: EventPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'TUTORING_GROUPS',
    path: '/tutoring-groups',
    icon: 'UserMultiple',
    Component: TutoringGroupsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'TUTORING_GROUPS',
        path: ':tutoringGroupId',
        icon: 'Undefined',
        Component: TutoringGroupPage,
        showInMenu: false
      },
      {
        title: 'TUTORING_GROUPS',
        path: ':tutoringGroupId/chat/:tutorId/:studentId',
        icon: 'Undefined',
        Component: ChatPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'EXAM_GROUPS',
    path: '/exam-groups',
    icon: 'DocumentSentiment',
    Component: ExamGroupsPage,
    showInMenu: true
  },
  {
    title: 'PROFILE',
    path: '/profile',
    icon: 'Undefined',
    Component: ProfilePage,
    showInMenu: false
  }
];
