import { EEventWeekdays } from '@constants';

export const CommonEventsTranslations = {
  EVENTS: {
    es: 'Eventos',
    en: 'Events',
    cat: 'Esdeveniments',
    eus: 'Ekitaldis'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  EVENT_TYPE: {
    es: 'Tipo de evento',
    en: 'Event type',
    cat: "Tipus d'esdeveniment",
    eus: 'Ekitaldi mota'
  },
  EVENT: {
    es: 'Evento',
    en: 'Event',
    cat: 'Esdeveniment',
    eus: 'Ekitaldi'
  },
  LIVE_CLASS: {
    es: 'Clase en directo',
    en: 'Live class',
    cat: 'Classe en directe',
    eus: 'Zuzeneko klasea'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data'
  },
  HOUR: {
    es: 'Hora',
    en: 'Hour',
    cat: 'Hora',
    eus: 'Ordua'
  },
  EVENT_LINK: {
    es: 'Enlace al evento',
    en: 'Event link',
    cat: "Enllaç a l'esdeveniment",
    eus: 'Gertaeraren esteka'
  },
  ATTACH_FILE: {
    es: 'Adjuntar archivo',
    en: 'Attach file',
    cat: 'Adjuntar arxiu',
    eus: 'Fitxategia erantsi'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi'
  },
  EVENT_CREATED_SUCCESSFULLY: {
    es: 'Evento creado correctamente',
    en: 'Event successfully created',
    cat: 'Esdeveniment creat amb èxit',
    eus: 'Ekitaldi ongi sortu da'
  },
  EVENT_UPDATED_SUCCESSFULLY: {
    es: 'Evento actualizado correctamente',
    en: 'Event successfully updated',
    cat: 'Esdeveniment actualitzat amb èxit',
    eus: 'Ekitaldi ongi eguneratu da'
  },
  CREATE_EVENT: {
    es: 'Nuevo evento',
    en: 'Create event',
    cat: 'Nou esdeveniment',
    eus: 'Ekitaldi berria'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Course',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  CATEGORIES: {
    es: 'Categorías',
    en: 'Categories',
    cat: 'Categories',
    eus: 'Kategoriak'
  },
  UPDATE_EVENT: {
    es: 'Actualizar evento',
    en: 'Update event',
    cat: 'Actualitzar esdeveniment',
    eus: 'Ekitaldi eguneratu'
  },
  SPEAKERS: {
    es: 'Ponentes',
    en: 'Speakers',
    cat: 'Ponents',
    eus: 'Hizlariak'
  },
  INSCRIBED: {
    es: 'Inscrito',
    en: 'Inscribed',
    cat: 'Inscrit',
    eus: 'Izena eman'
  },
  ATTEND: {
    es: 'Asistir',
    en: 'Attend',
    cat: 'Assistir',
    eus: 'Joan'
  },
  ATTACHMENT_REMOVED_CORRECTLY: {
    es: 'La grabación se ha eliminado correctamente',
    en: 'Recording successfully removed',
    cat: 'La gravació s`ha eliminat correctament',
    eus: 'Grabazioa ondo ezabatu da'
  },
  DAY_WEEK_0: {
    es: '💆‍♀️ Domingo',
    en: '💆‍♀️ Sunday',
    cat: '💆‍♀️ Diumenge',
    eus: '💆‍♀️ Igandea'
  },
  DAY_WEEK_1: {
    es: '🧟 Lunes',
    en: '🧟 Monday',
    cat: '🧟 Dilluns',
    eus: '🧟 Astelehena'
  },
  DAY_WEEK_2: {
    es: '☕️ Martes',
    en: '☕️ Tuesday',
    cat: '☕️ Dimarts',
    eus: '☕️ Asteartea'
  },
  DAY_WEEK_3: {
    es: '📚️ Miércoles',
    en: '📚️ Wednesday',
    cat: '📚️ Dimecres',
    eus: '📚️ Asteazkena'
  },
  DAY_WEEK_4: {
    es: '🧠 Jueves',
    en: '🧠 Thursday',
    cat: '🧠 Dijous',
    eus: '🧠 Osteguna'
  },
  DAY_WEEK_5: {
    es: '🍺 Viernes',
    en: '🍺 Friday',
    cat: '🍺 Divendres',
    eus: '🍺 Ostirala'
  },
  DAY_WEEK_6: {
    es: '🎉 Sábado',
    en: '🎉 Saturday',
    cat: '🎉 Dissabte',
    eus: '🎉 Larunbata'
  },
  MONTH: {
    es: 'Mes',
    en: 'Month',
    cat: 'Mes',
    eus: 'Hilabetea'
  },
  WEEK: {
    es: 'Semana',
    en: 'Week',
    cat: 'Setmana',
    eus: 'Astea'
  },
  LIST: {
    es: 'Lista',
    en: 'List',
    cat: 'Llista',
    eus: 'Zerrenda'
  },
  DAY: {
    es: 'Día',
    en: 'Day',
    cat: 'Dia',
    eus: 'Eguna'
  },
  TODAY: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur'
  },
  MORE_EVENTS_TEXT: {
    es: '+{{num}} Más',
    en: '+{{num}} More',
    cat: '+{{num}} Més',
    eus: '+{{num}} Gehiago'
  },
  DELETE_EVENT_CONFIRM: {
    es: '¿Estás seguro de eliminar este evento?',
    en: 'Are you sure you want to delete this event?',
    cat: 'Estàs segur que vols eliminar aquest esdeveniment?',
    eus: 'Ziur zaude ekitaldi hau ezabatu nahi duzula?'
  },
  ATTACHMENT_ADDED_CORRECTLY: {
    es: 'El archivo se ha añadido correctamente',
    en: 'The file has been added correctly',
    cat: 'L`arxiu s`ha afegit correctament',
    eus: 'Fitxategia zuzen gehitu da'
  },
  EVENT_REMOVED_SUCCESSFULLY: {
    es: 'Evento eliminado correctamente',
    en: 'Event removed successfully',
    cat: 'Esdeveniment eliminat correctament',
    eus: 'Ekitaldi ongi ezabatu da'
  },
  TAGS: {
    es: 'Categorías',
    en: 'Tags',
    cat: 'Etiquetes',
    eus: 'Etiketak'
  },
  SELECT_ALL: {
    es: 'Seleccionar todo',
    en: 'Select all',
    cat: 'Seleccionar tot',
    eus: 'Hautatu dena'
  },
  SELECT_NONE: {
    es: 'Deseleccionar todo',
    en: 'Deselect all',
    cat: 'Desseleccionar tot',
    eus: 'Hautapenik ez'
  },
  RECURRING_LABEL: {
    es: '¿Evento único o recurrente?',
    en: 'Is it a unique or recurring event?',
    cat: '¿Es un esdeveniment únic o recurrent?',
    eus: 'Eskubide bakarra edo errepikakorra da?'
  },
  FREQUENCY: {
    es: 'Frecuencia',
    en: 'Frequency',
    cat: 'Frecuencia',
    eus: 'Errepikapen periodikoa'
  },
  RECURRING_UNIQUE: {
    es: 'Único',
    en: 'Unique',
    cat: 'Únic',
    eus: 'Eskubide bakarra'
  },
  RECURRING: {
    es: 'Recurrente',
    en: 'Recurring',
    cat: 'Recurrent',
    eus: 'Errepikapen bakarra'
  },
  DATE_ONLY: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data'
  },
  TIME_ONLY: {
    es: 'Hora',
    en: 'Time',
    cat: 'Hora',
    eus: 'Ordua'
  },
  WEEKDAYS: {
    es: 'Días de la semana',
    en: 'Weekdays',
    cat: 'Dies de la setmana',
    eus: 'Astearen egunak'
  },
  [EEventWeekdays.MONDAY]: {
    es: 'Lunes',
    en: 'Monday',
    cat: 'Dilluns',
    eus: 'Astelehena'
  },
  [EEventWeekdays.TUESDAY]: {
    es: 'Martes',
    en: 'Tuesday',
    cat: 'Dimarts',
    eus: 'Asteartea'
  },
  [EEventWeekdays.WEDNESDAY]: {
    es: 'Miércoles',
    en: 'Wednesday',
    cat: 'Dimecres',
    eus: 'Asteazkena'
  },
  [EEventWeekdays.THURSDAY]: {
    es: 'Jueves',
    en: 'Thursday',
    cat: 'Dijous',
    eus: 'Osteguna'
  },
  [EEventWeekdays.FRIDAY]: {
    es: 'Viernes',
    en: 'Friday',
    cat: 'Divendres',
    eus: 'Ostirala'
  },
  [EEventWeekdays.SATURDAY]: {
    es: 'Sábado',
    en: 'Saturday',
    cat: 'Dissabte',
    eus: 'Larunbata'
  },
  [EEventWeekdays.SUNDAY]: {
    es: 'Domingo',
    en: 'Sunday',
    cat: 'Diumenge',
    eus: 'Igandea'
  },
  CONFIRM_MODAL_TITLE: {
    es: 'Actualizar todos los eventos recurrentes',
    en: 'Update all recurring events',
    cat: 'Actualitzar tots els esdeveniments recurrents',
    eus: 'Eguneratu errepikapen guztiak'
  },
  CONFIRM_MODAL_CONTENT: {
    es: '¿Deseas actualizar todos los eventos recurrentes o solo este?',
    en: 'Do you want to update all recurring events or just this one?',
    cat: 'Vols actualitzar tots els esdeveniments recurrents o només aquest?',
    eus: 'Errepikapen guztiak edo bakarrik hau eguneratu nahi dituzu?'
  },
  CONFIRM_MODAL_OK_TEXT: {
    es: 'Todos los eventos',
    en: 'All events',
    cat: 'Tots els esdeveniments',
    eus: 'Ekitaldi guztiak'
  },
  CONFIRM_MODAL_CANCEL_TEXT: {
    es: 'Este evento',
    en: 'This event',
    cat: 'Aquest esdeveniment',
    eus: 'Ekitaldi hau'
  },
  TIMEZONE: {
    es: 'Zona horaria',
    en: 'Timezone',
    cat: 'Zona horària',
    eus: 'Denbora-zonal'
  }
};
