import styled from 'styled-components';

export const OptionWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.gunmetal : theme.colors.solitude};
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.white : theme.colors.gunmetal)};
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  height: 48px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gunmetal};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 48px;
`;
