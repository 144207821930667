import { Button, Form } from 'antd';
import Lottie from 'lottie-react';
import styled, { css } from 'styled-components';

import ONBOARDING_BACKGROUND_SVG from '@/assets/onboarding_background.svg';

type ButtonProps = {
  step: number;
};

export const StyledForm: typeof Form = styled(Form)`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Wrapper = styled.div`
  height: 100%;
  background-image: url(${ONBOARDING_BACKGROUND_SVG});
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 40px 10px 30px 10px;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: fit-content;
  width: 100vw;
  justify-content: center;

  @media (min-width: 340px) {
    padding: 50px;
  }
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 850px;
    padding: 70px 40px 0px 40px;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    overflow: visible;
    padding: 40px 60px 0px 60px;
  }

  .umy-image {
    bottom: 0;
  }
`;

export const SlidesWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

export const SlideWrapper = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 90vw;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    max-width: 500px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-width: 700px;
  }
`;

export const StyledButton = styled(Button)<ButtonProps>(
  ({ step }) => css`
    z-index: 10;
    border-radius: 50px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      align-self: ${step === 6 || step === 0 ? `center` : step === 8 ? 'flex-start' : `unset`};
    }
  `
);

export const CurvedArrowVector = styled.img`
  position: absolute;
  bottom: 50px;
  right: 180px;
`;

export const StraightRightArrowVector = styled.img`
  position: absolute;
  bottom: -10px;
  right: 180px;
`;

export const StraightLeftArrowVector = styled.img`
  position: absolute;
  left: 180px;
`;

const getContentJustify = (step: number) => {
  switch (step) {
    case 0:
    case 7:
      return 'center';
    case 8:
      return 'flex-start';
    default:
      return 'space-between';
  }
};

export const ButtonWrapper = styled.div<ButtonProps>(
  ({ step }) => css`
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-top: 80px;
    margin-bottom: 16px;
    align-self: flex-start;
    flex-direction: column-reverse;
    gap: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      margin-top: 16px;
      flex-direction: row;
      align-items: center;
      justify-content: ${getContentJustify(step)};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
      margin-top: 100px;
      align-self: flex-end;
    }
  `
);

export const StyledLottie = styled(Lottie)`
  width: 160px;
  height: 160px;
  align-self: center;
`;

export const StyledUmyImg1 = styled.img`
  position: absolute;
  bottom: -400px;
  right: -350px;
  transition: bottom 0.5s ease-in-out;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    right: -280px;
  }
`;

export const StyledUmyImg2 = styled.img`
  position: absolute;
  bottom: -400px;
  left: -350px;
  transition: bottom 0.5s ease-in-out;
  height: 320px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    left: -220px;
  }
`;

export const StepperWrapper = styled.div`
  align-self: center;
`;
