import { AcademyService } from '../lib/academy/academy.service';
import { MyAcademyInternalUsersService } from '../lib/academy/my-academy-internal-users.service';
import { MyAcademyStudentsService } from '../lib/academy/my-academy-students.service';
import { MyAcademyTeachersService } from '../lib/academy/my-academy-teachers.service';
import { MyAcademyService } from '../lib/academy/my-academy.service';
import { ActivityService } from '../lib/activity/activity.service';
import { AuthService } from '../lib/auth/auth.service';
import { CertificateService } from '../lib/certificate/certificate.service';
import { ChatService } from '../lib/chat/chat.service';
import { CourseService } from '../lib/course/course.service';
import { DeliverableService } from '../lib/deliverable/deliverable.service';
import { EventService } from '../lib/event/event.service';
import { ExamGroupService } from '../lib/exam-group/exam-group.service';
import { FileService } from '../lib/file/file.service';
import { GamificationService } from '../lib/gamification/gamification.service';
import { MessagesService } from '../lib/messages/messages.service';
import { NebulonService } from '../lib/nebulon/nebulon.service';
import { NebulonQuestionAnsweringService } from '../lib/nebulon-question-answering/nebulon-question-answering.service';
import { NotificationService } from '../lib/notification/notification.service';
import { NpsVoteService } from '../lib/nps-vote/nps-vote.service';
import { PostService } from '../lib/post/post.service';
import { PostCategoryService } from '../lib/post-category/post-category.service';
import { PostTopicService } from '../lib/post-topic/post-topic.service';
import { ProblemReportService } from '../lib/problem-report/problem-report.service';
import { PromptService } from '../lib/prompt/prompt.service';
import { QuestionService } from '../lib/question/question.service';
import { QuestionReviewService } from '../lib/question-review/question-review.service';
import { QuizService } from '../lib/quiz/quiz.service';
import { QuizResultService } from '../lib/quiz-result/quiz-result.service';
import { ScrappedExamService } from '../lib/scrapped-exam/scrapped-exam.service';
import { ScrappedExamGroupService } from '../lib/scrapped-exam-group/scrapped-exam-group.service';
import { SectionService } from '../lib/section/section.service';
import { SectionReviewService } from '../lib/section-review/section-review.service';
import { SessionLogService } from '../lib/session-log/session-log.service';
import { ActivityStatsService } from '../lib/stats/activity-stats.service';
import { CoursesStatsService } from '../lib/stats/courses-stats.service';
import { ActivityNoteService } from '../lib/subscription/activity-note.service';
import { ActivityTrackingService } from '../lib/subscription/activity-tracking.service';
import { SectionTrackingService } from '../lib/subscription/section-tracking.service';
import { SubscriptionService } from '../lib/subscription/subscription.service';
import { TagService } from '../lib/tag/tag.service';
import { TaskService } from '../lib/task/task.service';
import { TicketService } from '../lib/ticket/ticket.service';
import { TranscriptionService } from '../lib/transcription/transcription.service';
import { TutoringGroupService } from '../lib/tutoring-group/tutoring-group.service';
import { UserService } from '../lib/user/user.service';
import { UserStatsByCourseService } from '../lib/user-stats-by-course/user-stats-by-course.service';

export const services = {
  AcademyService,
  MyAcademyInternalUsersService,
  MyAcademyStudentsService,
  MyAcademyTeachersService,
  MyAcademyService,
  ActivityService,
  AuthService,
  CertificateService,
  ChatService,
  CourseService,
  DeliverableService,
  EventService,
  ExamGroupService,
  FileService,
  GamificationService,
  MessagesService,
  NebulonService,
  NebulonQuestionAnsweringService,
  NotificationService,
  PostService,
  PostCategoryService,
  PostTopicService,
  ProblemReportService,
  PromptService,
  QuestionService,
  QuestionReviewService,
  QuizService,
  QuizResultService,
  ScrappedExamService,
  ScrappedExamGroupService,
  SectionService,
  SectionReviewService,
  SessionLogService,
  ActivityStatsService,
  CoursesStatsService,
  ActivityNoteService,
  ActivityTrackingService,
  SectionTrackingService,
  SubscriptionService,
  TagService,
  TaskService,
  TicketService,
  TranscriptionService,
  TutoringGroupService,
  UserService,
  UserStatsByCourseService,
  NpsVoteService
};
