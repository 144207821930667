import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 100%;

  .ant-form-item {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding: 0 32px;
  }
`;

export const VoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-form-item {
    margin: 0;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.marrow};
  padding: 12px 0;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding: 0 32px;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  font-weight: 600;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-size: 18px;
  padding: 16px;
  margin-top: 80px;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  text-align: center;
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
