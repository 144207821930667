import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { create as createStore } from 'zustand';

import { useMyTagsList } from '../tags';

import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

type IStore = {
  filteredTags: string[];
  setFilteredTags: (tags: string[]) => void;
  month: number;
  year: number;
  setMonthAndYear: (month: number, year: number) => void;
};

const store = createStore<IStore>(set => ({
  filteredTags: [],
  setFilteredTags: filteredTags => set({ filteredTags }),
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
  setMonthAndYear: (month, year) => set({ month, year })
}));

export const useEventsList = () => {
  const { filteredTags, setFilteredTags, month, year, setMonthAndYear } = store();
  const { tags } = useMyTagsList();

  useEffect(() => {
    if (tags && !filteredTags.length) setFilteredTags(tags.map(tag => tag._id));
  }, [tags]);

  const { isLoading, data, error } = useQuery({
    queryKey: ['events', filteredTags, month, year],
    queryFn: () => EventService.getAllEvents({ filteredTags, month, year })
  });

  return {
    events: data,
    isLoading,
    error,
    filteredTags,
    setFilteredTags,
    setMonthAndYear
  };
};
