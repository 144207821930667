export const CoursesStatsTranslations = {
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  TAG: {
    es: 'Formación',
    en: 'Tag',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  COURSES_STATS_EMPTY: {
    es: 'No se han encontrado cursos',
    en: 'No courses found',
    cat: "No s'han trobat cursos",
    eus: 'Ez dira ikastaroak aurkitu'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  STUDENT_COUNT: {
    es: 'Nº alumnos',
    en: 'Nº students',
    cat: 'Nº alumnes',
    eus: 'Ikasleen zenbakia'
  },
  QUIZ_COUNT: {
    es: 'Nº tests',
    en: 'Nº tests',
    cat: 'Nº tests',
    eus: 'Test kopurua'
  },
  QUIZ_AVERAGE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko nota'
  },
  TICKETS_COUNT: {
    es: 'Nº dudas',
    en: 'Nº tickets',
    cat: 'Nº dubtes',
    eus: 'Zalantz kopurua'
  },
  CSAT: {
    es: 'CSAT',
    en: 'CSAT',
    cat: 'CSAT',
    eus: 'CSAT'
  },
  ACTIVE_STUDENT_PERCENTAGE: {
    es: '% activos',
    en: '% active',
    cat: '% actius',
    eus: '% aktiboak'
  },
  INACTIVE_STUDENT_PERCENTAGE: {
    es: '% inactivos',
    en: '% inactive',
    cat: '% inactius',
    eus: '% ez-aktiboak'
  },
  SLEEP_STUDENT_PERCENTAGE: {
    es: '% dormidos',
    en: '% sleep',
    cat: '% adormits',
    eus: '% lo'
  },
  DOWNTURN_STUDENT_PERCENTAGE: {
    es: '% bajas',
    en: '% downturns',
    cat: '% baixes',
    eus: '% beherapenak'
  },
  COURSES_TOTAL_COUNT: {
    es: '{{total}} cursos',
    en: '{{total}} courses',
    cat: '{{total}} cursos',
    eus: '{{total}} ikastaro'
  },
  REQUEST_ALL_USERS_REPORT: {
    es: 'Solicitar informe de alumnos',
    en: 'Request student report',
    cat: 'Sol·licitar informe d’alumnes',
    eus: 'Ikasleen txostena eskatu'
  },
  REPORT_REQUESTED_CHECK_EMAIL: {
    es: 'Informe solicitado. Revisa tu correo electrónico en los próximos minutos',
    en: 'Report requested. Check your email in the next minutes',
    cat: 'Informe sol·licitat. Revisa el teu correu electrònic en els propers minuts',
    eus: 'Txostena eskatuta. Begiratu zure posta elektronikoa hurrengo minutuetan'
  },
  AVERAGE_CONNECTED_TIME: {
    es: 'Tiempo medio de conexión',
    en: 'Average connected time',
    cat: 'Temps mitjà de connexió',
    eus: 'Konektatze denbora ertaina'
  },
  CALCULATE_STATS: {
    es: 'Calcular estadísticas',
    en: 'Calculate stats',
    cat: 'Calcular estadístiques',
    eus: 'Estatistikak kalkulatu'
  },
  WARNING_CALCULATE_STATS: {
    es: 'Se trata de una operación costosa, ¿estás seguro de querer continuar?',
    en: 'This is a costly operation, are you sure you want to continue?',
    cat: 'Aquesta és una operació costosa, estàs segur que vols continuar?',
    eus: 'Hau operazio kostuduna da, ziur zaude jarraitu nahi duzula?'
  }
};
