import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const CoursesStatsService = sdk.getService('CoursesStatsService');

export const useMutateCoursesStatsList = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['stats', 'courses'] });

  const { mutate: calculateCourseStats, isPending: isCalculating } = useMutation({
    mutationFn: () => CoursesStatsService.calculateCoursesStats(),
    onSuccess: () => {
      onSuccess();
    }
  });

  return {
    calculateCourseStats,
    isWorking: isCalculating
  };
};
