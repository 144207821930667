import { DatePicker, Form, Input } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { FormWrapper, ImgWrapper, StyledSpan, Wrapper } from './StudentInfo.styles';

import ONBOARDING_STUDENT_INFO from '@/assets/umy_onboarding_student_info.svg';
import { RULES } from '@/constants';
import { useIsMobile } from '@/hooks';

export const StudentInfo = () => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const isMobile = useIsMobile('xs');

  return (
    <StepWrapper>
      <Wrapper>
        <div>
          <h2>
            <Trans i18nKey="STUDENT_INFO_TITLE" components={{ a: <StyledSpan /> }} />
          </h2>
          <Wrapper>
            <FormWrapper>
              <Form.Item name="name" label={t('NAME')} rules={[RULES.REQUIRED]}>
                <Input />
              </Form.Item>
              <Form.Item name="lastName" label={t('LAST_NAME')} rules={[RULES.REQUIRED]}>
                <Input />
              </Form.Item>
              <Form.Item name="legalId" label={t('LEGAL_ID')} rules={[RULES.REQUIRED]}>
                <Input placeholder={t('LEGAL_ID_PLACEHOLDER')} />
              </Form.Item>
              <Form.Item name="birthday" label={t('BIRTHDAY')} rules={[RULES.REQUIRED]}>
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </FormWrapper>
          </Wrapper>
        </div>
        {!isMobile && (
          <ImgWrapper>
            <img src={ONBOARDING_STUDENT_INFO} alt="Student Info logo" />
          </ImgWrapper>
        )}
      </Wrapper>
    </StepWrapper>
  );
};
