import { AnalyticsTrackEvents } from '@constants';
import { ITag } from '@sdk/contracts';
import { message } from 'antd';
import HelloSign from 'hellosign-embedded';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContractSignedWrapper, FormWrapper, SpinnerWrapper, TextWrapper } from './Contract.styles';

import PLATFORM_READY_LOTTIE from '@/assets/lotties/platform_ready.json';
import { LoadingCircleSpinner } from '@/components/Loaders';
import { useSegment } from '@/hooks';
import { StyledLottie } from '@/pages/Student/Onboarding/styles';
import { useMutateHelloSignContract, useProfile } from '@/store';

interface Props {
  step: number;
  isContractSigned: boolean;
  setIsContractSigned: (isContractSigned: boolean) => void;
}

interface ContractFormValues {
  name: string;
  lastName: string;
  legalId: string;
  birthday: Date;
}

interface IGenerateContract extends ContractFormValues {
  tags: ITag[];
  email: string;
  phone: string;
}

const helloSignClient = new HelloSign();

export const Contract: React.FC<Props> = ({ step, isContractSigned, setIsContractSigned }) => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const { profile } = useProfile();
  const { generateContractSignUrl, setSignatureReqId, isWorking } = useMutateHelloSignContract();
  const { fireEvent } = useSegment();

  useEffect(() => {
    if (step === 8 && profile && !isContractSigned) {
      if (import.meta.env.DEV) return setIsContractSigned(true);

      const { tags, email, phone, name, lastName, legalId, birthday } = profile;
      const loadContract = async (user: Partial<IGenerateContract>) => {
        generateContractSignUrl(user, {
          onSuccess: data => {
            const { signUrl, signatureRequestId } = data;
            setSignatureReqId(signatureRequestId);
            helloSignClient.open(signUrl, {
              clientId: import.meta.env['VITE_HELLOSIGN_CLIENT_ID'],
              allowCancel: false,
              container: document.getElementById('contract-wrapper') ?? undefined,
              locale: HelloSign.locales.ES_ES,
              skipDomainVerification: import.meta.env.PROD ? false : true
            });
          }
        });
      };
      loadContract({ tags, email, phone: phone ?? '', name, lastName, legalId, birthday });
      helloSignClient.on('sign', async () => {
        message.success(t('CONTRACT_SIGNED_SUCCESSFULLY'));
        setIsContractSigned(true);
        helloSignClient.close();
        fireEvent(AnalyticsTrackEvents.CONTRACT_SIGNED);
      });

      return () => {
        helloSignClient.off('sign');
      };
    }
    return;
  }, [step, profile]);

  if (!profile || !profile.legalId || !profile.birthday) return null;

  return (
    <FormWrapper>
      {isWorking && (
        <SpinnerWrapper>
          <LoadingCircleSpinner />
        </SpinnerWrapper>
      )}
      <div id="contract-wrapper"></div>
      {isContractSigned && (
        <ContractSignedWrapper>
          <StyledLottie animationData={PLATFORM_READY_LOTTIE} autoPlay loop={false} />
          <TextWrapper>{t('ALL_SET')}</TextWrapper>
          <TextWrapper>{t('YOU_CAN_START')}</TextWrapper>
        </ContractSignedWrapper>
      )}
      <small>{t('CONTRACT_HELP')}</small>
    </FormWrapper>
  );
};
