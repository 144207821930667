export enum EEventType {
  EVENT = 'EVENT',
  LIVE_CLASS = 'LIVE_CLASS'
}

export enum EEventWeekdays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export const EVENT_TYPES = [EEventType.EVENT, EEventType.LIVE_CLASS];
