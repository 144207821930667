import { ConfigProvider, Switch } from 'antd';
import esES from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Dispatch, useState } from 'react';
import { ThemeProvider as Provider, DefaultTheme } from 'styled-components';

import { defaultTheme, darkTheme } from './theme';

import 'dayjs/locale/es';

dayjs.extend(dayLocaleData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('es');

interface ThemeSwitcherProps {
  setTheme: Dispatch<DefaultTheme>;
}

export const ThemeSwitcher = ({ setTheme }: ThemeSwitcherProps) => {
  const handleSetTheme = (defaulTheme: boolean) =>
    defaulTheme ? setTheme(defaultTheme) : setTheme(darkTheme);
  return <Switch checkedChildren="Light" unCheckedChildren="Dark" onChange={handleSetTheme} />;
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme] = useState<DefaultTheme>(defaultTheme);

  return (
    <Provider theme={theme}>
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            fontFamily: theme.fontFamily.primary,
            colorPrimary: theme.colors.gunmetal,
            colorSuccess: theme.colors.success,
            colorError: theme.colors.error,
            colorWarning: theme.colors.warning,
            colorInfo: theme.colors.link,
            colorBgLayout: theme.colors.white
          },
          components: {
            Alert: {
              colorInfoBg: theme.colors.neoghost
            },
            Modal: {
              colorPrimary: theme.colors.gunmetal
            },
            Button: {
              fontFamily: theme.fontFamily.primary,
              borderRadius: 100,
              borderRadiusXS: 100,
              borderRadiusSM: 100,
              borderRadiusLG: 100
            },
            Segmented: {
              trackBg: theme.colors.solitude
            }
          }
        }}>
        {children}
      </ConfigProvider>
    </Provider>
  );
};

export default ThemeProvider;
