import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
`;

export const ItemWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  border: ${({ theme, isSelected }) =>
    isSelected ? `2px solid ${theme.colors.neoreg}` : `1px solid ${theme.colors.marrow}`};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.neowhite : theme.colors.white};
  transition: background 0.3s, border 0.3s;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
