import { FC, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import {
  LottieWrapper,
  StyledH1,
  StyledLottie,
  TextWrapper,
  Wrapper
} from './LoadingPlatform.styles';

import SAND_CLOCK_LOTTIE from '@/assets/lotties/sand_clock_loader.json';
import UMY_ONBOARDING_GENERATING_PLATFORM from '@/assets/umy_onboarding_generating_platform.svg';
import UMY_ONBOARDING_PLATFORM_READY from '@/assets/umy_onboarding_platform_ready.svg';
import { useProfile } from '@/store';

interface Props {
  step: number;
  setIsPlatformReady: (isPlatformReady: boolean) => void;
}

export const LoadingPlatform: FC<Props> = ({ step, setIsPlatformReady }) => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const { profile } = useProfile();
  const [visibleText, setVisibleText] = useState(1);

  useEffect(() => {
    if (step === 7) setIsPlatformReady(false);
    setVisibleText(1);
    const timer1 = setTimeout(() => {
      setVisibleText(2);
    }, 3000);
    const timer2 = setTimeout(() => {
      setVisibleText(3);
      setIsPlatformReady(true);
    }, 9000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [step]);

  const isLoading = useMemo(() => visibleText < 3, [visibleText]);

  if (!profile) return null;

  const { name: userName } = profile;
  return (
    <StepWrapper>
      <Wrapper>
        <StyledH1>
          {t(isLoading ? 'GENERATING_PLATFORM' : 'THANKS_FOR_WAITING', {
            name: userName.charAt(0).toLocaleUpperCase() + userName.slice(1)
          })}
        </StyledH1>
        <LottieWrapper>
          {isLoading ? (
            <>
              <StyledLottie animationData={SAND_CLOCK_LOTTIE} autoPlay loop />
              <img src={UMY_ONBOARDING_GENERATING_PLATFORM} alt="Generating platform" />
            </>
          ) : (
            <img src={UMY_ONBOARDING_PLATFORM_READY} alt="Generating platform" />
          )}
        </LottieWrapper>
        {visibleText === 1 && <TextWrapper>{t('PREPARING_PLATFORM')}</TextWrapper>}
        {visibleText === 2 && (
          <TextWrapper>
            <Trans i18nKey="FUN_FACT" components={{ a: <strong /> }} />
          </TextWrapper>
        )}
        {visibleText === 3 && <TextWrapper>{t('PLATFORM_READY')}</TextWrapper>}
      </Wrapper>
    </StepWrapper>
  );
};
