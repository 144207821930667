import { AnalyticsTrackEvents } from '@constants';
import { dateProvider } from '@utils';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  DateWrapper,
  EmptyWrapper,
  InfoWrapper,
  ItemWrapper,
  TitleWrapper,
  Wrapper
} from './MyExamGroups.styles';

import { Icon } from '@/components/Icon';
import { useSegment } from '@/hooks';
import { useMyExamGroupsList } from '@/store';

export const MyExamGroups = () => {
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const { examGroups } = useMyExamGroupsList();
  const { fireEvent } = useSegment();

  const fireWidgetEvent = () =>
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'EXAM_GROUPS'
    });

  const handleGoToExamGroups = () => {
    navigateTo('/profile?activeTab=EXAM_GROUPS');
    fireWidgetEvent();
  };

  const handleGoToExamGroupDetail = (examGroupId: string) => {
    navigateTo(`/community/exam-groups?examGroupId=${examGroupId}`);
    fireWidgetEvent();
  };

  return (
    <Wrapper>
      {examGroups && examGroups.length > 0 ? (
        <>
          {examGroups.map((examGroup, index) => {
            return (
              <div key={index} style={{ width: '100%' }}>
                <ItemWrapper
                  key={examGroup._id}
                  onClick={() => handleGoToExamGroupDetail(examGroup._id)}>
                  <InfoWrapper>
                    <DateWrapper>
                      {t('PUBLISHED_AT', {
                        date: dateProvider.format(examGroup.publishedAt, 'dd/MM/yyyy')
                      })}
                    </DateWrapper>
                    <TitleWrapper>{examGroup.name}</TitleWrapper>
                  </InfoWrapper>
                  <Icon i="ChevronRight" />
                </ItemWrapper>
                <Divider type="horizontal" />
              </div>
            );
          })}
        </>
      ) : (
        <EmptyWrapper>
          <div>{t('YOUR_EXAM_GROUPS_EMPTY')}</div>
          <Button icon={<Icon i="CalendarAdd" />} onClick={handleGoToExamGroups}>
            {t('JOIN_EXAM_GROUP')}
          </Button>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};
