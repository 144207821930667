import { Divider } from 'antd';

import { Wrapper } from './WidgetBase.styles';

import { WidgetProps } from '@/types';

type Props = {
  children: React.ReactNode;
} & WidgetProps;

export const WidgetBase: React.FC<Props> = ({
  title,
  subTitle,
  addon,
  children,
  position = {},
  headerSize = 'medium',
  hoverable = false,
  backgroundColor,
  onHeaderClick
}) => {
  return (
    <Wrapper
      hoverable={hoverable}
      headerSize={headerSize}
      backgroundColor={backgroundColor}
      style={{ gridArea: Object.values(position).join(' / ') }}>
      {(title || addon) && (
        <>
          <div onClick={onHeaderClick} style={{ cursor: onHeaderClick ? 'pointer' : 'auto' }}>
            <div>
              {title && <div>{title}</div>}
              {subTitle && <div className="subtitle">{subTitle}</div>}
            </div>
            {addon && <div>{addon}</div>}
          </div>
          <Divider />
        </>
      )}
      <section>{children}</section>
    </Wrapper>
  );
};
