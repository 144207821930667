import { EVerticalNames } from '@constants';
import { createQueryString, encodeQueryParams } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request/request.service';
import { IAssociateTagToCourse, ICreateSection } from '../section/section.contract';

import {
  ICreateCourse,
  ICourse,
  IReorderSections,
  IReorderActivities,
  IReorderFolders,
  IReorderMaterial,
  IMoveMaterial,
  IUpdateMaterialName,
  IUpdateFolder,
  IRemoveFolder,
  IAssignTeacherToCourse,
  ICreateFolder,
  ICourseForSelector,
  ITeacherCourse,
  ICourseToDuplicate,
  IDownloadUploadedFilesData,
  IUpdateComplementaryMaterialTitle
} from './course.contract';

export class CourseService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getCourses = async (search?: string, pagination?: IPagination, bypass?: boolean) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      bypass
    });
    return this.fetcher<IPaginatedResponse<ICourse>>(`/courses?${query}`);
  };

  public findAllTeacherCourses = async (teacherId: string) => {
    return this.fetcher<ITeacherCourse[]>(`/courses/all-courses-for-teacher/${teacherId}`);
  };

  public findAllCoursesForSelectors = async () => {
    return this.fetcher<ICourseForSelector[]>(`/courses/all-courses-for-selectors`);
  };

  public findAllCoursesByTags = async (tagsIds: string[]) => {
    const query = encodeQueryParams({ tags: tagsIds.join(',') });
    return this.fetcher<ICourse[]>(`/courses/by-tags?${query}`);
  };

  public createCourse = async (course: ICreateCourse) => {
    return this.poster<ICourse, ICreateCourse>('/courses', course);
  };

  public getCourse = async (courseId: string) => {
    return this.fetcher<ICourse>(`/courses/${courseId}`);
  };

  public updateCourse = async (course: Partial<ICourse>) => {
    return this.putter<ICourse, Partial<ICourse>>(`/courses/${course._id}`, course);
  };

  public removeCourse = async (courseId: string) => {
    return this.deleter(`/courses/${courseId}`);
  };

  public assignTeacherToCourse = async ({ teacherId, courseId }: IAssignTeacherToCourse) => {
    return this.putter<ICourse, undefined>(`/courses/${courseId}/assign-teacher/${teacherId}`);
  };

  public createFolder = async ({ courseId, name }: ICreateFolder) => {
    return this.poster<ICourse, { name: string }>(`/courses/${courseId}/folder`, { name });
  };

  public updateFolder = async ({ courseId, folder }: IUpdateFolder) => {
    return this.putter(`/courses/${courseId}/folder/${folder._id}`, folder);
  };

  public updateMaterialNameInFolder = async ({
    courseId,
    fileId,
    newName
  }: IUpdateMaterialName) => {
    return this.putter(`/courses/${courseId}/file/${fileId}`, { name: newName });
  };

  public removeMaterialInFolder = async (fileId: string) => {
    return this.deleter(`/courses/file/${fileId}`);
  };

  public removeFolder = async ({ courseId, folderId }: IRemoveFolder) => {
    return this.deleter(`/courses/${courseId}/folder/${folderId}`);
  };

  public reorderCourseSections = async ({ courseId, newSectionsOrder }: IReorderSections) => {
    return this.putter(`/courses/${courseId}/reorder-sections`, newSectionsOrder);
  };

  public removeTagFromCourses = async (tagId: string) => {
    return this.putter(`courses/tags/${tagId}`);
  };

  public downloadMaterial = async (courseId: string, folderId?: string) => {
    const query = createQueryString({
      filters: {
        folderId
      }
    });
    return this.fetcher<any>(`/courses/compress-pdfs/${courseId}?${query}`, {
      responseType: 'blob'
    });
  };

  public reorderSectionActivities = async ({
    courseId,
    sectionId,
    newActivitiesOrder
  }: IReorderActivities) => {
    return this.putter(
      `/courses/${courseId}/sections/${sectionId}/reorder-activities`,
      newActivitiesOrder
    );
  };

  public reorderCourseFolders = async ({ courseId, newFoldersOrder }: IReorderFolders) => {
    return this.putter(`/courses/${courseId}/reorder-folders`, newFoldersOrder);
  };

  public reorderFolderMaterial = async ({
    courseId,
    folderId,
    newMaterialOrder
  }: IReorderMaterial) => {
    return this.putter(
      `/courses/${courseId}/folder/${folderId}/reorder-material`,
      newMaterialOrder
    );
  };

  public moveMaterialBetweenFolders = async ({
    courseId,
    originFolder,
    destinationFolder,
    materialIds
  }: IMoveMaterial) => {
    return this.putter(`/courses/${courseId}/folder/${originFolder}/move-material`, {
      destinationFolder,
      materialIds: materialIds.map(m => m._id)
    });
  };

  public getCoursesTags = async (coursesIds: string[]) => {
    const query = encodeQueryParams({ courses: coursesIds.join(',') });
    return this.fetcher<string[]>(`/courses/courses-tags?${query}`);
  };

  public duplicateCourse = async (courseToDuplicate: ICourseToDuplicate) => {
    return this.poster<ICourse, ICourseToDuplicate>(`/courses/duplicate`, courseToDuplicate);
  };

  public downloadUploadedFilesData = async () => {
    return this.fetcher<IDownloadUploadedFilesData[]>(`/courses/stats/files/download`);
  };

  public updateComplementaryMaterialTitle = async (values: IUpdateComplementaryMaterialTitle) => {
    const { courseId, title } = values;
    return this.putter(`/courses/${courseId}/complementary-material`, { title });
  };

  public addComplementaryMaterialSection = async (createSection: ICreateSection) => {
    const { course, section } = createSection;
    return this.poster(`/courses/${course._id}/complementary-material`, { name: section.name });
  };

  public addIntroductionSection = async (createSection: ICreateSection) => {
    const { course, section } = createSection;
    return this.poster(`/courses/${course._id}/introduction`, { name: section.name });
  };

  public associateTagToCourse = async (data: IAssociateTagToCourse) => {
    return this.putter(`/courses/${data.courseId}/associate-tag/${data.tagId}`);
  };
}
