import { EOnboardingChosenReason, EOnboardingDiscoverySource } from '@constants';

export const StudentOnboardingTranslations = {
  [EOnboardingDiscoverySource.GOOGLE]: {
    es: 'Busqué en Google',
    en: 'I searched on Google',
    cat: 'Vaig buscar a Google',
    eus: 'Google-n bilatu nuen'
  },
  [EOnboardingDiscoverySource.SOCIAL_MEDIA]: {
    es: 'En una red social',
    en: 'On social media',
    cat: 'En una xarxa social',
    eus: 'Sare sozialean'
  },
  [EOnboardingDiscoverySource.ADVERTISEMENT]: {
    es: 'En publicidad de redes sociales',
    en: 'In social media advertising',
    cat: 'En publicitat de xarxes socials',
    eus: 'Sare sozialen publizitatean'
  },
  [EOnboardingDiscoverySource.FLYER]: {
    es: 'En una pancarta por la calle',
    en: 'On a street banner',
    cat: 'En una pancarta pel carrer',
    eus: 'Kaleko kartelan'
  },
  [EOnboardingDiscoverySource.FRIEND]: {
    es: 'Recomendación de amigo/a',
    en: 'Friend recommendation',
    cat: 'Recomanació d`amic/a',
    eus: 'Lagun baten gomendioa'
  },
  [EOnboardingDiscoverySource.OTHER]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altra',
    eus: 'Beste bat'
  },
  CHOSEN_REASON_TITLE: {
    es: '¿Y finalmente por qué motivo elegiste a Ucademy ☺️?',
    en: 'And finally, why did you choose Ucademy ☺️?',
    cat: 'I finalment, per quin motiu vas triar Ucademy ☺️?',
    eus: 'Eta azkenik, zergatik hautatu zinen Ucademy ☺️?'
  },
  [`${EOnboardingChosenReason.QUIZ}_OPTION`]: {
    es: 'Los tipo test',
    en: 'The multiple choice questions',
    cat: 'Els tipus test',
    eus: 'Test motak'
  },
  [`${EOnboardingChosenReason.MICROLESSON}_OPTION`]: {
    es: 'Microclases en video',
    en: 'Video microlessons',
    cat: 'Microclasses en vídeo',
    eus: 'Bideo mikrolehenak'
  },
  [`${EOnboardingChosenReason.TEACHER_FOLLOW_UP}_OPTION`]: {
    es: 'Seguimiento del profesorado',
    en: 'Teacher follow-up',
    cat: 'Seguiment del professorat',
    eus: 'Irakasleen jarraipena'
  },
  [`${EOnboardingChosenReason.SUCCESS_GUARANTEE}_OPTION`]: {
    es: 'Garantía de aprobado',
    en: 'Pass guarantee',
    cat: 'Garantia d`aprovat',
    eus: 'Onarpenaren bermea'
  },
  [`${EOnboardingChosenReason.ATTENTION}_OPTION`]: {
    es: 'Atención de mi asesor/a',
    en: 'Attention from my advisor',
    cat: 'Atenció del meu assessor/a',
    eus: 'Nire aholkulariaren arreta'
  },
  [`${EOnboardingChosenReason.HELP_IN_EVERYTHING}_OPTION`]: {
    es: 'Por ayudarme en todo',
    en: 'For helping me in everything',
    cat: 'Per ajudar-me en tot',
    eus: 'Denetan laguntzeko'
  },
  [`${EOnboardingChosenReason.OTHER}_OPTION`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altra',
    eus: 'Beste bat'
  },
  CAN_YOU_TELL_US_HIS_NAME: {
    es: '¿Puedes decirnos como se llama?',
    en: 'Can you tell us his name?',
    cat: 'Pots dir-nos com es diu?',
    eus: 'Zer izena duela esan dezakezu?'
  },
  ONLY_TO_KNOW_IF_WE_KNOW_HIM: {
    es: 'Solo para saber si lo conocemos',
    en: 'Just to know if we know him',
    cat: 'Només per saber si el coneixem',
    eus: 'Guk ezagutzen dugula jakiteko bakarrik'
  },
  NAME_AND_SURNAME: {
    es: 'Nombre y apellidos',
    en: 'Name and surname',
    cat: 'Nom i cognoms',
    eus: 'Izena eta abizenak'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu'
  },
  GREAT: {
    es: '¡Genial!',
    en: 'Great!',
    cat: 'Genial!',
    eus: 'Zoragarria!'
  },
  DO_YOU_KNOW_OTHER_PEOPLE: {
    es: '¿Y conoces a más gente que estudie lo mismo que tú?',
    en: 'Do you know more people who study the same as you?',
    cat: 'I coneixes més gent que estudiï el mateix que tu?',
    eus: 'Eta ezagutzen duzu zuk bera ikasten duen beste jende bat?'
  },
  YES: {
    es: 'Sí',
    en: 'Yes',
    cat: 'Sí',
    eus: 'Bai'
  },
  NO: {
    es: 'No',
    en: 'No',
    cat: 'No',
    eus: 'Ez'
  },
  TELL_US: {
    es: 'Cuéntanos',
    en: 'Tell us',
    cat: "Explica'ns",
    eus: 'Esan zaitez'
  },
  HOW_DID_YOU_DISCOVER_US: {
    es: '¿Cómo nos conociste por primera vez?',
    en: 'How did you first hear about us?',
    cat: 'Com ens vas conèixer per primera vegada?',
    eus: 'Nola ezagutu zintuzten lehenengo aldian?'
  },
  WHY_DID_YOU_CHOOSE_US: {
    es: '¿Por qué nos has elegido?',
    en: 'Why did you choose us?',
    cat: 'Per què ens has triat?',
    eus: 'Zergatik hautatu zaitugu?'
  }
};
